import Routes from './Routes'
import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyAJxj-ClR0urs8MNClUKO5MVo2Bq8hVoAw",
  authDomain: "questionario-901ce.firebaseapp.com",
  projectId: "questionario-901ce",
  storageBucket: "questionario-901ce.appspot.com",
  messagingSenderId: "516122245151",
  appId: "1:516122245151:web:b196e7e1b7fdde3ec83d2a"
};
firebase.initializeApp(firebaseConfig);

export default function App() {
  return (
    <div className="App" >
      <Routes />
    </div>
  )
}
