import React, { useEffect, useState } from 'react';

export default function Login() {
    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="wrapper">
                            <div className="row no-gutters">
                                <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                    <div className="contact-wrap w-100 p-md-5 p-4">
                                        <div style={{ textAlign: 'center' }}>
                                            <img src='images/logo.png' />
                                        </div>
                                        <h4 style={{ marginTop: 30 }}>Olá, Time Entrega Já, Tudo bem?</h4>
                                        <p>Estamos aqui para conversar sobre algo realmente empolgante: a nossa Avaliação de Desempenho! Este é um momento especial, onde juntos – sim, você e nós do RH e da equipe de processos, vamos dar um passo adiante para tornar nosso ambiente de trabalho melhor.</p>
                                        <p>Você deve estar se perguntando, “Por que isso é tão importante?” Bem, aqui vai: cada um de vocês é uma peça fundamental no quebra-cabeça da nossa empresa. Suas ideias, seu esforço e suas conquistas são o que nos impulsionam para frente. Por isso, queremos ouvir de você! Como você se sente em relação ao seu trabalho? O que te deixa animado para vir trabalhar todos os dias? E o que podemos fazer para tornar as coisas ainda melhores?</p>
                                        <p>Esta avaliação não é só um formulário a ser preenchido. É a sua voz sendo ouvida, é reconhecer suas conquistas brilhantes e, juntos, explorar como podemos crescer ainda mais. É sobre alinhar seus sonhos e metas com os da nossa organização, criando um futuro onde todos brilhamos.</p>
                                        <p>Então, sente-se confortavelmente e vamos mergulhar nesta jornada de auto conhecimento e crescimento conjunto. Estamos super animados para ouvir suas ideias e trabalhar juntos para fazer nosso ambiente de trabalho ser o melhor possível!</p>
                                        <p>Contamos com a cooperação de todos vocês, e desde já agradecemos!</p>
                                        <p><b>Com carinho, <br /> Equipe de RH e Processos</b></p>
                                        <div className="col-md-12 " style={{marginTop:20,textAlign:'end'}}>
                                            <div className="form-group">
                                                <a href='/questao' type="submit" className="btn btn-primary" >
                                                    Estou ciente, começar questionário
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-5 d-flex align-items-stretch" >
                                    <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                                        <h3>Avaliação de Desempenho<br />Um Caminho para o Crescimento </h3>
                                        <p className="mb-4"></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}