import React, { useState } from 'react';
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2';

export default function DetalheQuestao(props) {
    const [dado] = useState(props.location.state ? props.location.state.questao : '')
    const [servicos] = useState({
        auth: firebase.auth(),
        questionario: firebase.firestore().collection('questionario')
    })

    // Dados Pessoais
    const [sucesso, setSucesso] = useState(false)
    const [nome, setNome] = useState(dado.dados.nome);
    const [email, setEmail] = useState(dado.dados.email);
    const [unidade, setUnidade] = useState(dado.dados.unidade);
    const [setor, setSetor] = useState(dado.dados.setor);
    const [funcao, setFuncao] = useState(dado.dados.funcao);
    const [faculdade, setFaculdade] = useState(dado.dados.faculdade);
    const [curso, setCurso] = useState(dado.dados.curso);
    const [observacaoPontoFraco, setObservacaoPontoFraco] = useState(dado.dados.observacaoPontoFraco);
    const [observacaoPontoForte, setObservacaoPontoForte] = useState(dado.dados.observacaoPontoForte);
    const [nivelDesempenho, setNivelDesempenho] = useState(dado.dados.nivelDesempenho);
    const [tipoTrabalho, setTipoTrabalho] = useState(dado.dados.tipoTrabalho);
    const [desafiosTrabalho, setDesafiosTrabalho] = useState(dado.dados.desafiosTrabalho);
    const [melhoria, setMelhoria] = useState(dado.dados.melhoria);
    const [mudancaTrabalho, setMudancaTrabalho] = useState(dado.dados.mudancaTrabalho);
    const [pressao, setPressao] = useState(dado.dados.pressao);
    const [desenvolvimentoPessoal, setDesenvolvimentoPessoal] = useState(dado.dados.desenvolvimentoPessoal);
    const [habilidades, setHabilidades] = useState(dado.dados.habilidades);
    const [disponibilidadeEstado, setDisponibilidadeEstado] = useState(dado.dados.disponibilidadeEstado);
    const [sugestaoMelhoria, setSugestaoMelhoria] = useState(dado.dados.sugestaoMelhoria);


    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="wrapper">
                            <div className="row no-gutters">
                                {sucesso ?
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                        <div style={{ textAlign: 'center' }} className="contact-wrap w-100 p-md-5 p-4">
                                            <i style={{ color: 'green' }} class="fa fa-check fa-5x" aria-hidden="true"></i>
                                            <h5 style={{ color: 'green' }}>Questionário finalizado com sucesso</h5>
                                            <h1>{nome}</h1>
                                            <p style={{ marginTop: 20 }}> Agradecemos por compartilhar detalhes valiosos sobre seu perfil. Sua contribuição é essencial para nos ajudar a entender melhor suas necessidades e criar um ambiente de trabalho mais satisfatório.</p>
                                            <div className="form-group">
                                                <a href='/' type="submit" className="btn btn-primary">Ir para tela inicial</a>
                                            </div>
                                            <div style={{ textAlign: 'center', marginTop: 50 }}>
                                                <img src='images/logo.png' style={{ height: 30 }} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <div style={{ textAlign: 'center' }}>
                                                <img src='images/logo.png' />
                                            </div>
                                            <p>Abaixo estarão listadas, todos as questões disponíveis para serem respondidas. Todas as respostas serão meramente guardadas e analisadas pelo setor responsável.</p>
                                            <form method="POST" id="contactForm" name="contactForm" className="contactForm">
                                                <div className="row">
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">1. Email da Entrega Já <b style={{ color: 'red' }}>*</b></label>
                                                            <input disabled value={email} type="text" className="form-control" name="name" id="name" placeholder="email@entregaja.com.br" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">2. Nome Completo: <b style={{ color: 'red' }}>*</b></label>
                                                            <input disabled value={nome} type="text" className="form-control" name="name" id="name" placeholder="Seu Nome" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="email">3. Qual a unidade você representa? <b style={{ color: 'red' }}>*</b></label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">BEL-PA</label>
                                                                <input checked={unidade === 'BEL-PA'?true:false} value={'BEL-PA'} className="form-check-input" type="radio" name="flexRadioDefault0" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">GRU-SP</label>
                                                                <input checked={unidade === 'GRU-SP'?true:false} value={'GRU-SP'} className="form-check-input" type="radio" name="flexRadioDefault0" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">IMP-MA</label>
                                                                <input checked={unidade === 'IMP-MA'?true:false} value={'IMP-MA'} className="form-check-input" type="radio" name="flexRadioDefault0" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">SLZ-MA</label>
                                                                <input checked={unidade === 'SLZ-MA'?true:false} value={'SLZ-MA'} className="form-check-input" type="radio" name="flexRadioDefault0" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="email">4. Qual o seu Departamento? <b style={{ color: 'red' }}>*</b></label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">ATENDIMENTO</label>
                                                                <input  checked={setor === 'ATENDIMENTO'?true:false} value={'ATENDIMENTO'} className="form-check-input" type="radio" name="flexRadioDefault10" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">COMERCIAL</label>
                                                                <input checked={setor === 'COMERCIAL'?true:false} value={'COMERCIAL'} className="form-check-input" type="radio" name="flexRadioDefault10" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">FINANCEIRO</label>
                                                                <input checked={setor === 'FINANCEIRO'?true:false} value={'FINANCEIRO'} className="form-check-input" type="radio" name="flexRadioDefault10" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">RECURSOS HUMANOS E DEPARTAMENTO PESSOAL</label>
                                                                <input checked={setor === 'RECURSOS HUMANOS E DEPARTAMENTO PESSOAL'?true:false} value={'RECURSOS HUMANOS E DEPARTAMENTO PESSOAL'} className="form-check-input" type="radio" name="flexRadioDefault10" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">OPERACIONAL</label>
                                                                <input checked={setor === 'OPERACIONAL'?true:false} value={'OPERACIONAL'} className="form-check-input" type="radio" name="flexRadioDefault10" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">TECNOLOGIA (TI)</label>
                                                                <input checked={setor === 'TECNOLOGIA (TI)'?true:false} value={'TECNOLOGIA (TI)'} className="form-check-input" type="radio" name="flexRadioDefault10" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">5. Qual é sua função atualmente em registro? <b style={{ color: 'red' }}>*</b></label>
                                                            <p>Ex: Auxiliar de Expedição, Assistente de Compras, etc</p>
                                                            <input value={funcao} disabled type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta" />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">6. Possui Faculdade? Caso não, possui interesse em fazer alguma? Digite na opção em outros qual é de seu interesse: <b style={{ color: 'red' }}>*</b></label>
                                                            <p>Ex: Auxiliar de Expedição, Assistente de Compras, etc </p>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Sim</label>
                                                                <input checked={faculdade === 'SIM'?true:false} value={'SIM'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Não</label>
                                                                <input checked={faculdade === 'NAO'?true:false} value={'NAO'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Outro</label>
                                                                <input checked={faculdade === 'OUTRO'?true:false} value={'OUTRO'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <input value={descricaoFaculdade} disabled type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta caso sua resposta seja Outro" />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">6. Qual seu nível de escolaridade? <b style={{ color: 'red' }}>*</b></label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Fundamental Completo</label>
                                                                <input checked={faculdade === 'Ensino Fundamental Completo'?true:false} value={'Ensino Fundamental Completo'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Fundamental Incompleto</label>
                                                                <input checked={faculdade === 'Ensino Fundamental Incompleto'?true:false} value={'Ensino Fundamental Incompleto'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Médio Completo</label>
                                                                <input checked={faculdade === 'Ensino Médio Completo'?true:false} value={'Ensino Médio Completo'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Médio Incompleto</label>
                                                                <input checked={faculdade === 'Ensino Médio Incompleto'?true:false} value={'Ensino Médio Incompleto'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Superior Completo</label>
                                                                <input checked={faculdade === 'Ensino Superior Completo'?true:false} value={'Ensino Superior Completo'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Superior Incompleto</label>
                                                                <input checked={faculdade === 'Ensino Superior Incompleto'?true:false} value={'Ensino Superior Incompleto'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Pós-Graduação Completo</label>
                                                                <input checked={faculdade === 'Pós-Graduação Completo'?true:false} value={'Pós-Graduação Completo'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Pós-Graduação Incompleto</label>
                                                                <input checked={faculdade === 'Pós-Graduação Incompleto'?true:false} value={'Pós-Graduação Incompleto'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            {/* <input value={descricaoFaculdade} onChange={handleDescricaoFaculdade} type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta caso sua resposta se" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 style={{marginTop:30}}">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">7. Possuí algum curso de capacitação? Se sim, qual?</label>
                                                            <input value={curso} disabled type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">8. Quais são seus principais pontos fracos que você considera e que pretende melhorar? <b style={{ color: 'red' }}>*</b></label>
                                                            <p>Liste os que mais se enquadra dentro do seu perfil atualmente</p>
                                                            {dado.dados.pontosFracos.map((d, index) =>
                                                                <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                    <label className="label" htmlFor="email">{d.questao}</label>
                                                                    <input  checked={dado.dados.pontosFracos[index].check? true:false} className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                </div>
                                                            )}
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <input value={observacaoPontoFraco} disabled type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">9. Quais são seus principais pontos fortes que você considera?</label>
                                                            <p>Liste os que mais se enquadra dentro do seu perfil atualmente</p>
                                                            {dado.dados.pontosFortes.map((d, index) =>
                                                                <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                    <label className="label" htmlFor="email">{d.questao}</label>
                                                                    <input  checked={dado.dados.pontosFortes[index].check? true:false} className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                </div>
                                                            )}
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <input value={observacaoPontoForte} disabled type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" >10. Você se considera uma pessoa comunicativa em qual nível?</label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <div className='col-md-4'>
                                                                    <label className="label" htmlFor="email">Relativamente Comunicativa</label>
                                                                </div>
                                                                <div className='col-md-6 row' style={{ textAlign: 'center' }}>
                                                                    <div className='col-md-1'>
                                                                        <label className="label">1</label>
                                                                        <input checked={nivelDesempenho === '1'?true:false} style={{ marginLeft: 1 }} value={'1'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>

                                                                    <div className='col-md-1'>
                                                                        <label className="label">2</label>
                                                                        <input checked={nivelDesempenho === '2'?true:false} style={{ marginLeft: 1 }} value={'2'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>
                                                                    <div className='col-md-1'>
                                                                        <label className="label">3</label>
                                                                        <input checked={nivelDesempenho === '3'?true:false} style={{ marginLeft: 1 }} value={'3'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>
                                                                    <div className='col-md-1'>
                                                                        <label className="label">4</label>
                                                                        <input checked={nivelDesempenho === '4'?true:false} style={{ marginLeft: 1 }} value={'4'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>
                                                                    <div className='col-md-1'>
                                                                        <label className="label">5</label>
                                                                        <input checked={nivelDesempenho === '5'?true:false} style={{ marginLeft: 1 }} value={'5'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <label className="label" htmlFor="email">Muito Comunicativa</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">11. Você prefere trabalhos em equipe ou individual?</label>
                                                            <input value={tipoTrabalho} disabled type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">12. Há alguma situação em que você enfrentou desafios ao trabalhar com colegas de equipe? Como você lidou com isso?</label>
                                                            <input value={desafiosTrabalho} disabled type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">13. Você sugeriu ou implementou alguma melhoria significativa nos processos de trabalho? Se sim relate um pouco dessas implementações:</label>
                                                            <input value={melhoria} disabled type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">14. Você se considera uma pessoa apta a mudanças no trabalho?  </label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Sim</label>
                                                                <input checked={mudancaTrabalho === 'Sim'?true:false} value={'Sim'} className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Não</label>
                                                                <input checked={mudancaTrabalho === 'Não'?true:false} value={'Não'} className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">15. Você se considera uma pessoa para trabalhar sob pressão? </label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Sim</label>
                                                                <input checked={pressao === 'Sim'?true:false} value={'Sim'} className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Não</label>
                                                                <input checked={pressao === 'Não'?true:false} value={'Não'} className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }} >
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">16. Diante do seu desenvolvimento profissional, tem interesse em trabalhar em algum outro departamento ou função? Se sim, relate qual: </label>
                                                            <input value={desenvolvimentoPessoal} disabled va type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">17. Você está tomando algum passo para desenvolver suas habilidades e avançar em sua carreira no momento? Se sim quais? </label>
                                                            <input disabled value={habilidades} type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">18. Você teria disponibilidade de morar em outro estado? </label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Sim</label>
                                                                <input checked={disponibilidadeEstado === 'Sim'?true:false} value={'Sim'} className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Não</label>
                                                                <input checked={disponibilidadeEstado === 'Não'?true:false} value={'Não'} className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">19. Qual sugestão você teria para melhorar o clima e a satisfação na empresa como um todo? </label>
                                                            <textarea value={sugestaoMelhoria} disabled type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="col-md-12 " style={{ marginTop: 20, textAlign: 'end' }}>
                                                <div className="form-group">
                                                    <button onClick={()=> window.history.back()} type="submit" className="btn btn-primary">Voltar</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }



                                <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                                    <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                                        <h3>Avaliação de Desempenho<br />Um Caminho para o Crescimento </h3>
                                        <p className="mb-4"></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}