import React, { useState } from 'react';
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2';

export default function Login() {

    const [servicos] = useState({
        auth: firebase.auth(),
        questionario: firebase.firestore().collection('questionario')
    })

    // Dados Pessoais
    const [sucesso, setSucesso] = useState(false)
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [unidade, setUnidade] = useState('');
    const [setor, setSetor] = useState('');
    const [funcao, setFuncao] = useState('');
    const [faculdade, setFaculdade] = useState('');
    const [descricaoFaculdade, setDescricaoFaculdade] = useState('');
    const [curso, setCurso] = useState('');
    const [pontosFracos, setPontosFracos] = useState([
        { value: 0, questao: 'DESORGANIZADO', check: false },
        { value: 1, questao: 'FALTA DE FOCO', check: false },
        { value: 2, questao: 'NÃO FALAR BEM EM PÚBLICO', check: false },
        { value: 3, questao: 'NÃO GOSTAR DE CORRER RISCOS', check: false },
        { value: 4, questao: 'DIFICULDADE EM DELEGAR', check: false },
        { value: 5, questao: 'DIFICULDADE EM DIZER NÃO', check: false },
        { value: 6, questao: 'IMPACIENTE', check: false },
        { value: 7, questao: 'RESISTENTE A MUDANÇAS', check: false },
        { value: 8, questao: 'NÃO TRABALHAR MUITO BEM EM EQUIPE', check: false },
        { value: 9, questao: 'TIMIDEZ', check: false },
        { value: 10, questao: 'SE AUTOCOBRAR', check: false },
        { value: 11, questao: 'ORGANIZAÇÃO EXAGERADA', check: false },
        { value: 12, questao: 'OUTRO', check: false },
    ]);
    const [observacaoPontoFraco, setObservacaoPontoFraco] = useState('');
    const [pontosFortes, setPontosFortes] = useState([
        { value: 0, questao: 'CRIATIVIDADE', check: false },
        { value: 1, questao: 'PROATIVIDADE', check: false },
        { value: 2, questao: 'ANÁLITICO', check: false },
        { value: 3, questao: 'EMPATIA', check: false },
        { value: 4, questao: 'COMUNICAÇÃO', check: false },
        { value: 5, questao: 'PACIENTE', check: false },
        { value: 6, questao: 'DEDICAÇÃO', check: false },
        { value: 7, questao: 'FLEXIBILIDADE', check: false },
        { value: 8, questao: 'RESPEITO', check: false },
        { value: 9, questao: 'ADAPTAÇÃO DE CULTURA OU MUDANÇAS', check: false },
        { value: 10, questao: 'HONESTIDADE', check: false },
        { value: 11, questao: 'INOVADOR', check: false },
        { value: 12, questao: 'ÁGIL EM RESOLUÇÃO DE PROBLEMAS', check: false },
        { value: 13, questao: 'LIDERANÇA', check: false },
        { value: 14, questao: 'OUTRO', check: false },
    ]);
    const [observacaoPontoForte, setObservacaoPontoForte] = useState('');
    const [nivelDesempenho, setNivelDesempenho] = useState('');
    const [tipoTrabalho, setTipoTrabalho] = useState('');
    const [desafiosTrabalho, setDesafiosTrabalho] = useState('');
    const [melhoria, setMelhoria] = useState('');
    const [mudancaTrabalho, setMudancaTrabalho] = useState('');
    const [pressao, setPressao] = useState('');
    const [desenvolvimentoPessoal, setDesenvolvimentoPessoal] = useState('');
    const [habilidades, setHabilidades] = useState('');
    const [disponibilidadeEstado, setDisponibilidadeEstado] = useState('');
    const [sugestaoMelhoria, setSugestaoMelhoria] = useState('');

    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleUnidade = (event) => {
        event.persist();
        setUnidade(event.target.value);
    }
    const handleSetor = (event) => {
        event.persist();
        setSetor(event.target.value);
    }
    const handleFuncao = (event) => {
        event.persist();
        setFuncao(event.target.value);
    }
    const handleFaculdade = (event) => {
        event.persist();
        setFaculdade(event.target.value);
    }
    const handleCurso = (event) => {
        event.persist();
        setCurso(event.target.value);
    }
    const handleObsPontoFraco = (event) => {
        event.persist();
        setObservacaoPontoFraco(event.target.value);
    }
    const handleObsPontoForte = (event) => {
        event.persist();
        setObservacaoPontoForte(event.target.value);
    }
    const handleNivelDesempenho = (event) => {
        event.persist();
        setNivelDesempenho(event.target.value);
    }
    const handleTipoTrabalho = (event) => {
        event.persist();
        setTipoTrabalho(event.target.value);
    }
    const handleDesafiosTrabaalho = (event) => {
        event.persist();
        setDesafiosTrabalho(event.target.value);
    }
    const handleMelhoria = (event) => {
        event.persist();
        setMelhoria(event.target.value);
    }
    const handleMudancaTrabalho = (event) => {
        event.persist();
        setMudancaTrabalho(event.target.value);
    }
    const handlePressao = (event) => {
        event.persist();
        setPressao(event.target.value);
    }
    const handleDesenvolvimentoPessoal = (event) => {
        event.persist();
        setDesenvolvimentoPessoal(event.target.value);
    }
    const handleHabilidades = (event) => {
        event.persist();
        setHabilidades(event.target.value);
    }
    const handleDisponibilidadeEstado = (event) => {
        event.persist();
        setDisponibilidadeEstado(event.target.value);
    }
    const handleSugestaoMelhoria = (event) => {
        event.persist();
        setSugestaoMelhoria(event.target.value);
    }

    function enviaFormulario() {
        var validaPonotosFracos = false;
        var validaPonotosFortes = false;
        pontosFracos.forEach((d) => {
            if (d.check) {
                validaPonotosFracos = true
            }
        })
        pontosFortes.forEach((d) => {
            if (d.check) {
                validaPonotosFortes = true
            }
        })
        if (!email || !email.includes('@')) {
            mensagemAlerta('Por favor digite seu email')
            return
        }
        if (!nome) {
            mensagemAlerta('Por favor digite seu nome')
            return
        }
        if (!unidade) {
            mensagemAlerta('Por favor selecione sua Unidade')
            return
        }
        if (!setor) {
            mensagemAlerta('Por favor selecione seu departamento')
            return
        }
        if (!funcao) {
            mensagemAlerta('Por favor diga sobre sua Função')
            return
        }
        if (!faculdade) {
            mensagemAlerta('Por favor escolha se você possui faculdade')
            return
        }
        if (!validaPonotosFracos) {
            mensagemAlerta('Escolha ao menos um ponto fraco')
            return
        }
        if (!validaPonotosFortes) {
            mensagemAlerta('Escolha ao menos um ponto forte')
            return
        }
        if (!nivelDesempenho) {
            mensagemAlerta('Por favor escolha seu nível de desempenho')
            return
        }
        if (!tipoTrabalho) {
            mensagemAlerta('Por favor diga se prefere trabalho em equipe ou individual')
            return
        }
        if (!desafiosTrabalho) {
            mensagemAlerta('Por favor fale sobre os desafios infrentados')
            return
        }
        if (!melhoria) {
            mensagemAlerta('Por favor fale sobre melhorias implementadas')
            return
        }
        if (!mudancaTrabalho) {
            mensagemAlerta('Por favor escolha sobre você ser hapta a mudanças')
            return
        }
        if (!pressao) {
            mensagemAlerta('Por favor escolha sobre você considera trabalhar sob pressão')
            return
        }
        if (!pressao) {
            mensagemAlerta('Por favor escolha sobre você considera trabalhar sob pressão')
            return
        }
        if (!desenvolvimentoPessoal) {
            mensagemAlerta('Por favor false sobre seu desenvolvimento pessoal')
            return
        }
        if (!habilidades) {
            mensagemAlerta('Por favor false sobre desenvolver suas habilidades')
            return
        }
        if (!disponibilidadeEstado) {
            mensagemAlerta('Por favor selecione se você tem dispinibilidade de morar em outro Estado')
            return
        }
        if (!sugestaoMelhoria) {
            mensagemAlerta('Por favor nos diga uma sugestão de melhoria')
            return
        }
        const dados = {
            email: email,
            nome: nome,
            unidade: unidade,
            setor: setor,
            funcao: funcao,
            faculdade: faculdade,
            descricaoFaculdade: descricaoFaculdade,
            curso: curso,
            pontosFracos: pontosFracos,
            observacaoPontoFraco: observacaoPontoFraco,
            pontosFortes: pontosFortes,
            observacaoPontoForte: observacaoPontoForte,
            nivelDesempenho: nivelDesempenho,
            tipoTrabalho: tipoTrabalho,
            desafiosTrabalho: desafiosTrabalho,
            melhoria: melhoria,
            mudancaTrabalho: mudancaTrabalho,
            pressao: pressao,
            desenvolvimentoPessoal: desenvolvimentoPessoal,
            habilidades: habilidades,
            disponibilidadeEstado:disponibilidadeEstado,
            sugestaoMelhoria: sugestaoMelhoria,

        }
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja enviar o seu questionário ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não, quero revisar'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Enviando questionário..')
                servicos.questionario.where('id', '==', 'avaliacaodesempenho01').get().then((res) => {
                    if (res.size === 0) {
                        servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').where('dados.email', '==', email).get().then((resUser) => {
                            if (resUser.size > 0) {
                                alert('Este email já realizou cadastro do questionário')
                            } else {
                                
                                servicos.questionario.doc('avaliacaodesempenho01').set({
                                    id: 'avaliacaodesempenho01',
                                    nome: 'Avaliação de Desempenho Um Caminho para o Crescimento'
                                }).then(() => {
                                    servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').add({
                                        dados
                                    }).then((id) => {
                                        servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').doc(id.id).update({
                                            id: id.id,
                                            dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                                        }).then(() => {
                                            Swal.close();
                                            setSucesso(true)
                                        })
                                    })
                                })
                            }
                        })

                    } else {
                        servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').where('dados.email', '==', email).get().then((resUser) => {
                            if (resUser.size > 0) {
                                alert('Este email já realizou cadastro do questionário')
                            } else {
                                servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').add({
                                    dados
                                }).then((id) => {
                                    servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').doc(id.id).update({
                                        id: id.id,
                                        dataCadastro: moment().format('YYYY-MM-DD HH:mm')
                                    }).then(() => {
                                        Swal.close();
                                        setSucesso(true)
                                    })
                                })
                            }
                        })
                    }
                })
            }
        });
        
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    } function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }


    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="wrapper">
                            <div className="row no-gutters">
                                {sucesso ?
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                        <div style={{ textAlign: 'center' }} className="contact-wrap w-100 p-md-5 p-4">
                                            <i style={{ color: 'green' }} class="fa fa-check fa-5x" aria-hidden="true"></i>
                                            <h5 style={{ color: 'green' }}>Questionário finalizado com sucesso</h5>
                                            <h1>{nome}</h1>
                                            <p style={{ marginTop: 20 }}> Agradecemos por compartilhar detalhes valiosos sobre seu perfil. Sua contribuição é essencial para nos ajudar a entender melhor suas necessidades e criar um ambiente de trabalho mais satisfatório.</p>
                                            <div className="form-group">
                                                <a href='/' type="submit" className="btn btn-primary">Ir para tela inicial</a>
                                            </div>
                                            <div style={{ textAlign: 'center', marginTop: 50 }}>
                                                <img src='images/logo.png' style={{ height: 30 }} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <div style={{ textAlign: 'center' }}>
                                                <img src='images/logo.png' />
                                            </div>
                                            <p>Abaixo estarão listadas, todos as questões disponíveis para serem respondidas. Todas as respostas serão meramente guardadas e analisadas pelo setor responsável.</p>
                                            <form method="POST" id="contactForm" name="contactForm" className="contactForm">
                                                <div className="row">
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">1. Email da Entrega Já <b style={{ color: 'red' }}>*</b></label>
                                                            <input value={email} onChange={handleEmail} type="text" className="form-control" name="name" id="name" placeholder="email@entregaja.com.br" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">2. Nome Completo: <b style={{ color: 'red' }}>*</b></label>
                                                            <input value={nome} onChange={handleNome} type="text" className="form-control" name="name" id="name" placeholder="Seu Nome" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="email">3. Qual a unidade você representa? <b style={{ color: 'red' }}>*</b></label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">BEL-PA</label>
                                                                <input onChange={handleUnidade} value={'BEL-PA'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">GRU-SP</label>
                                                                <input onChange={handleUnidade} value={'GRU-SP'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">IMP-MA</label>
                                                                <input onChange={handleUnidade} value={'IMP-MA'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">SLZ-MA</label>
                                                                <input onChange={handleUnidade} value={'SLZ-MA'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="email">4. Qual o seu Departamento? <b style={{ color: 'red' }}>*</b></label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">ATENDIMENTO</label>
                                                                <input onChange={handleSetor} value={'ATENDIMENTO'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">COMERCIAL</label>
                                                                <input onChange={handleSetor} value={'COMERCIAL'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">FINANCEIRO</label>
                                                                <input onChange={handleSetor} value={'FINANCEIRO'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">RECURSOS HUMANOS E DEPARTAMENTO PESSOAL</label>
                                                                <input onChange={handleSetor} value={'RECURSOS HUMANOS E DEPARTAMENTO PESSOAL'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">OPERACIONAL</label>
                                                                <input onChange={handleSetor} value={'OPERACIONAL'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">TECNOLOGIA (TI)</label>
                                                                <input onChange={handleSetor} value={'TECNOLOGIA (TI)'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">5. Qual é sua função atualmente em registro? <b style={{ color: 'red' }}>*</b></label>
                                                            <p>Ex: Auxiliar de Expedição, Assistente de Compras, etc</p>
                                                            <input value={funcao} onChange={handleFuncao} type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">6. Qual seu nível de escolaridade? <b style={{ color: 'red' }}>*</b></label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Fundamental Completo</label>
                                                                <input onChange={handleFaculdade} value={'Ensino Fundamental Completo'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Fundamental Incompleto</label>
                                                                <input onChange={handleFaculdade} value={'Ensino Fundamental Incompleto'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Médio Completo</label>
                                                                <input onChange={handleFaculdade} value={'Ensino Médio Completo'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Médio Incompleto</label>
                                                                <input onChange={handleFaculdade} value={'Ensino Médio Incompleto'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Superior Completo</label>
                                                                <input onChange={handleFaculdade} value={'Ensino Superior Completo'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Ensino Superior Incompleto</label>
                                                                <input onChange={handleFaculdade} value={'Ensino Superior Incompleto'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Pós-Graduação Completo</label>
                                                                <input onChange={handleFaculdade} value={'Pós-Graduação Completo'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Pós-Graduação Incompleto</label>
                                                                <input onChange={handleFaculdade} value={'Pós-Graduação Incompleto'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                            </div>
                                                            {/* <input value={descricaoFaculdade} onChange={handleDescricaoFaculdade} type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta caso sua resposta se" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 style={{marginTop:30}}">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">7. Possuí algum curso de capacitação? Se sim, qual?</label>
                                                            <input value={curso} onChange={handleCurso} type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">8. Quais são seus principais pontos fracos que você considera e que pretende melhorar? <b style={{ color: 'red' }}>*</b></label>
                                                            <p>Liste os que mais se enquadra dentro do seu perfil atualmente</p>
                                                            {pontosFracos.map((d, index) =>
                                                                <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                    <label className="label" htmlFor="email">{d.questao}</label>
                                                                    <input onClick={() => pontosFracos[index].check = !pontosFracos[index].check} className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                </div>
                                                            )}
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <input value={observacaoPontoFraco} onChange={handleObsPontoFraco} type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">9. Quais são seus principais pontos fortes que você considera?</label>
                                                            <p>Liste os que mais se enquadra dentro do seu perfil atualmente</p>
                                                            {pontosFortes.map((d, index) =>
                                                                <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                    <label className="label" htmlFor="email">{d.questao}</label>
                                                                    <input onClick={() => pontosFortes[index].check = !pontosFortes[index].check} className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                </div>
                                                            )}
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <input value={observacaoPontoForte} onChange={handleObsPontoForte} type="text" className="form-control" name="name" id="name" placeholder="Sua Resposta" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" >10. Você se considera uma pessoa comunicativa em qual nível?</label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <div className='col-md-4'>
                                                                    <label className="label" htmlFor="email">Relativamente Comunicativa</label>
                                                                </div>
                                                                <div className='col-md-6 row' style={{ textAlign: 'center' }}>
                                                                    <div className='col-md-1'>
                                                                        <label className="label">1</label>
                                                                        <input onChange={handleNivelDesempenho} style={{ marginLeft: 1 }} value={'1'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>

                                                                    <div className='col-md-1'>
                                                                        <label className="label">2</label>
                                                                        <input onChange={handleNivelDesempenho} style={{ marginLeft: 1 }} value={'2'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>
                                                                    <div className='col-md-1'>
                                                                        <label className="label">3</label>
                                                                        <input onChange={handleNivelDesempenho} style={{ marginLeft: 1 }} value={'3'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>
                                                                    <div className='col-md-1'>
                                                                        <label className="label">4</label>
                                                                        <input onChange={handleNivelDesempenho} style={{ marginLeft: 1 }} value={'4'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>
                                                                    <div className='col-md-1'>
                                                                        <label className="label">5</label>
                                                                        <input onChange={handleNivelDesempenho} style={{ marginLeft: 1 }} value={'5'} className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <label className="label" htmlFor="email">Muito Comunicativa</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">11. Você prefere trabalhos em equipe ou individual?</label>
                                                            <input value={tipoTrabalho} onChange={handleTipoTrabalho} type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">12. Há alguma situação em que você enfrentou desafios ao trabalhar com colegas de equipe? Como você lidou com isso?</label>
                                                            <input value={desafiosTrabalho} onChange={handleDesafiosTrabaalho} type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">13. Você sugeriu ou implementou alguma melhoria significativa nos processos de trabalho? Se sim relate um pouco dessas implementações:</label>
                                                            <input value={melhoria} onChange={handleMelhoria} type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">14. Você se considera uma pessoa apta a mudanças no trabalho?  </label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Sim</label>
                                                                <input onChange={handleMudancaTrabalho} value={'Sim'} className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Não</label>
                                                                <input onChange={handleMudancaTrabalho} value={'Não'} className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">15. Você se considera uma pessoa para trabalhar sob pressão? </label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Sim</label>
                                                                <input onChange={handlePressao} value={'Sim'} className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Não</label>
                                                                <input onChange={handlePressao} value={'Não'} className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }} >
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">16. Diante do seu desenvolvimento profissional, tem interesse em trabalhar em algum outro departamento ou função? Se sim, relate qual: </label>
                                                            <input value={desenvolvimentoPessoal} onChange={handleDesenvolvimentoPessoal} va type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">17. Você está tomando algum passo para desenvolver suas habilidades e avançar em sua carreira no momento? Se sim quais? </label>
                                                            <input onChange={handleHabilidades} value={habilidades} type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">18. Você teria disponibilidade de morar em outro estado? </label>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Sim</label>
                                                                <input onChange={handleDisponibilidadeEstado} value={'Sim'} className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                                            </div>
                                                            <div style={{ marginTop: 10, marginLeft: 20 }} className='row'>
                                                                <label className="label" htmlFor="email">Não</label>
                                                                <input onChange={handleDisponibilidadeEstado} value={'Não'} className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ marginTop: 30 }}>
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">19. Qual sugestão você teria para melhorar o clima e a satisfação na empresa como um todo? </label>
                                                            <textarea value={sugestaoMelhoria} onChange={handleSugestaoMelhoria} type="text" className="form-control" name="subject" id="subject" placeholder="Sua respsota" />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </form>
                                            <div className="col-md-12 " style={{ marginTop: 20, textAlign: 'end' }}>
                                                <div className="form-group">
                                                    <button onClick={() => enviaFormulario()} type="submit" className="btn btn-primary">Finalizar Questionário</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }



                                <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                                    <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                                        <h3>Avaliação de Desempenho<br />Um Caminho para o Crescimento </h3>
                                        <p className="mb-4"></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}