import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import firebase from 'firebase'
import { Link } from 'react-router-dom';
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Acesso() {
    const [servicos] = useState({
        auth: firebase.auth(),
        questionario: firebase.firestore().collection('questionario')
    })
    const [excel, setExcel] = useState([])
    const [lista, setLista] = useState([])
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [setor, setSetor] = useState('')
    const [acesso, setAcesso] = useState(false)
    const [acessos] = useState([
        { email: 'admin@entregaja.com.br', senha: 'Infrakerberos#2024adm', setor: 'FULL' },
        { email: 'ti@entregaja.com.br', senha: 'Infrakerberos#2024ti', setor: 'TECNOLOGIA (TI)' },
        { email: 'operacional@entregaja.com.br', senha: 'Infrakerberos#2024op', setor: 'OPERACIONAL' },
        { email: 'rh@entregaja.com.br', senha: 'Infrakerberos#2024rh', setor: 'RECURSOS HUMANOS E DEPARTAMENTO PESSOAL' },
        { email: 'financeiro@entregaja.com.br', senha: 'Infrakerberos#2024fn', setor: 'FINANCEIRO' },
        { email: 'comercial@entregaja.com.br', senha: 'Infrakerberos#2024cm', setor: 'COMERCIAL' },
        { email: 'atendimento@entregaja.com.br', senha: 'Infrakerberos#2024at', setor: 'ATENDIMENTO' },
    ])

    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    function login() {
        if (!email || !email.includes('@')) {
            mensagemAlerta('Digite seu email')
            return
        }
        if (!senha) {
            mensagemAlerta('Digite sua Senha')
            return
        }
        var verificarAcesso = false;
        acessos.filter((d) => {
            if (d.email === email) {
                if (d.senha === senha) {
                    localStorage.setItem('setor', "" + d.setor)
                    verificarAcesso = true
                }
            }
        })
        if (verificarAcesso) {
            mensagemLoading('Carregando questionário')
            const setor = localStorage.getItem('setor')
            setSetor(setor)
            const list = [];
            var pontosFracos = '';
            var pontosFortes = '';
            if(setor == 'FULL'){
                servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').get().then((res) => {
                    const dados = res.docs.map((d) => d.data());
                    setLista(dados)
                    setAcesso(true)
                    localStorage.setItem('acesso', true)
                    dados.forEach((doc) => {
                        doc.dados.pontosFortes.forEach((pf)=>{
                            if(pf.check){
                                pontosFortes = pontosFortes == ''?pf.questao: pontosFortes+', \n'+pf.questao
                            }
                        })
                        doc.dados.pontosFracos.forEach((pf)=>{
                            if(pf.check){
                                pontosFracos = pontosFracos == ''?pf.questao: pontosFracos+', \n'+pf.questao
                            }
                        })
                        list.push([
                            { value: doc.dados.nome, style: { font: { sz: "11", } } },
                            { value: doc.dados.email, style: { font: { sz: "11", } } },
                            { value: doc.dados.unidade, style: { font: { sz: "11", } } },
                            { value: doc.dados.setor, style: { font: { sz: "11", } } },
                            { value: doc.dados.funcao, style: { font: { sz: "11", } } },
                            { value: doc.dados.faculdade, style: { font: { sz: "11", } } },
                            { value: doc.dados.descricaoFaculdade ? doc.dados.descricaoFaculdade : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: doc.dados.curso ? doc.dados.curso : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: pontosFracos, style: { font: { sz: "11", } } },
                            { value: doc.dados.observacaoPontoFraco ? doc.dados.observacaoPontoFraco : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: pontosFortes, style: { font: { sz: "11", } } },
                            { value: doc.dados.observacaoPontoForte ? doc.dados.observacaoPontoForte : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: doc.dados.nivelDesempenho, style: { font: { sz: "11", } } },
                            { value: doc.dados.tipoTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.desafiosTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.melhoria, style: { font: { sz: "11", } } },
                            { value: doc.dados.mudancaTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.pressao, style: { font: { sz: "11", } } },
                            { value: doc.dados.desenvolvimentoPessoal, style: { font: { sz: "11", } } },
                            { value: doc.dados.habilidades, style: { font: { sz: "11", } } },
                            { value: doc.dados.sugestaoMelhoria, style: { font: { sz: "11", } } },
                        ])
                    })
                    Swal.close();
                    setExcel([{
                        columns: [
                            { title: "NOME", width: { wch: 20 } },//pixels width 
                            { title: "EMAIL", width: { wch: 15 } },//char width 
                            { title: "UNIDADE", width: { wch: 30 } },//char width 
                            { title: "DEPARTAMENTO", width: { wch: 30 } },//char width 
                            { title: "QUAL É SUA FUNÇÃO ATUALMENTE EM REGISTRO?", width: { wch: 30 } },
                            { title: "POSSUI FACULDADE? CASO NÃO, POSSUI INTERESSE EM FAZER ALGUMA? DIGITE NA OPÇÃO EM OUTROS QUAL É DE SEU INTERESSE:", width: { wch: 20 } },
                            { title: "INTERESSE EM FACULDADE", width: { wch: 20 } },
                            { title: "SE POSSUÍ FACULDADE, QUAL CURSO VOCÊ FEZ OU ESTÁ CURSANDO?", width: { wch: 15 } },
                            { title: "QUAIS SÃO SEUS PRINCIPAIS PONTOS FRACOS QUE VOCÊ CONSIDERA E QUE PRETENDE MELHORAR?", width: { wch: 15 } },
                            { title: "OUTROS PONTOS FRACOS", width: { wch: 15 } },
                            { title: "QUAIS SÃO SEUS PRINCIPAIS PONTOS FORTES QUE VOCÊ CONSIDERA?", width: { wch: 18 } },
                            { title: "OUTROS PONTOS FORTES", width: { wch: 15 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA COMUNICATIVA EM QUAL NÍVEL?", width: { wch: 18 } },
                            { title: "VOCÊ PREFERE TRABALHOS EM EQUIPE OU INDIVIDUAL?", width: { wch: 10 } },
                            { title: "HÁ ALGUMA SITUAÇÃO EM QUE VOCÊ ENFRENTOU DESAFIOS AO TRABALHAR COM COLEGAS DE EQUIPE? COMO VOCÊ LIDOU COM ISSO?", width: { wch: 30 } },
                            { title: "VOCÊ SUGERIU OU IMPLEMENTOU ALGUMA MELHORIA SIGNIFICATIVA NOS PROCESSOS DE TRABALHO? SE SIM RELATE UM POUCO DESSAS IMPLEMENTAÇÕES:", width: { wch: 16 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA APTA A MUDANÇAS NO TRABALHO?", width: { wch: 18 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA PARA TRABALHAR SOB PRESSÃO?", width: { wch: 15 } },
                            { title: "DIANTE DO SEU DESENVOLVIMENTO PROFISSIONAL, TEM INTERESSE EM TRABALHAR EM ALGUM OUTRO DEPARTAMENTO OU FUNÇÃO? SE SIM, RELATE QUAL:", width: { wch: 15 } },
                            { title: "VOCÊ ESTÁ TOMANDO ALGUM PASSO PARA DESENVOLVER SUAS HABILIDADES E AVANÇAR EM SUA CARREIRA NO MOMENTO? SE SIM QUAIS?", width: { wch: 20 } },
                            { title: "QUAL SUGESTÃO VOCÊ TERIA PARA MELHORAR O CLIMA E A SATISFAÇÃO NA EMPRESA COMO UM TODO?", width: { wch: 20 } },
                            // { title: "OBSERVAÇÃO", width: { wch: 20 } },
                        ],
                        data: list
                    }])
                })
            }else{
                servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').where('dados.setor', '==', '' + setor).get().then((res) => {
                    const dados = res.docs.map((d) => d.data());
                    setLista(dados)
                    setAcesso(true)
                    localStorage.setItem('acesso', true)
                    dados.forEach((doc) => {
                        doc.dados.pontosFortes.forEach((pf)=>{
                            if(pf.check){
                                pontosFortes = pontosFortes == ''?pf.questao: pontosFortes+', \n'+pf.questao
                            }
                        })
                        doc.dados.pontosFracos.forEach((pf)=>{
                            if(pf.check){
                                pontosFracos = pontosFracos == ''?pf.questao: pontosFracos+', \n'+pf.questao
                            }
                        })
                        list.push([
                            { value: doc.dados.nome, style: { font: { sz: "11", } } },
                            { value: doc.dados.email, style: { font: { sz: "11", } } },
                            { value: doc.dados.unidade, style: { font: { sz: "11", } } },
                            { value: doc.dados.setor, style: { font: { sz: "11", } } },
                            { value: doc.dados.funcao, style: { font: { sz: "11", } } },
                            { value: doc.dados.faculdade, style: { font: { sz: "11", } } },
                            { value: doc.dados.descricaoFaculdade ? doc.dados.descricaoFaculdade : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: doc.dados.curso ? doc.dados.curso : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: pontosFracos, style: { font: { sz: "11", } } },
                            { value: doc.dados.observacaoPontoFraco ? doc.dados.observacaoPontoFraco : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: pontosFortes, style: { font: { sz: "11", } } },
                            { value: doc.dados.observacaoPontoForte ? doc.dados.observacaoPontoForte : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: doc.dados.nivelDesempenho, style: { font: { sz: "11", } } },
                            { value: doc.dados.tipoTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.desafiosTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.melhoria, style: { font: { sz: "11", } } },
                            { value: doc.dados.mudancaTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.pressao, style: { font: { sz: "11", } } },
                            { value: doc.dados.desenvolvimentoPessoal, style: { font: { sz: "11", } } },
                            { value: doc.dados.habilidades, style: { font: { sz: "11", } } },
                            { value: doc.dados.sugestaoMelhoria, style: { font: { sz: "11", } } },
                        ])
                    })
                    Swal.close();
                    setExcel([{
                        columns: [
                            { title: "NOME", width: { wch: 20 } },//pixels width 
                            { title: "EMAIL", width: { wch: 15 } },//char width 
                            { title: "UNIDADE", width: { wch: 30 } },//char width 
                            { title: "DEPARTAMENTO", width: { wch: 30 } },//char width 
                            { title: "QUAL É SUA FUNÇÃO ATUALMENTE EM REGISTRO?", width: { wch: 30 } },
                            { title: "POSSUI FACULDADE? CASO NÃO, POSSUI INTERESSE EM FAZER ALGUMA? DIGITE NA OPÇÃO EM OUTROS QUAL É DE SEU INTERESSE:", width: { wch: 20 } },
                            { title: "INTERESSE EM FACULDADE", width: { wch: 20 } },
                            { title: "SE POSSUÍ FACULDADE, QUAL CURSO VOCÊ FEZ OU ESTÁ CURSANDO?", width: { wch: 15 } },
                            { title: "QUAIS SÃO SEUS PRINCIPAIS PONTOS FRACOS QUE VOCÊ CONSIDERA E QUE PRETENDE MELHORAR?", width: { wch: 15 } },
                            { title: "OUTROS PONTOS FRACOS", width: { wch: 15 } },
                            { title: "QUAIS SÃO SEUS PRINCIPAIS PONTOS FORTES QUE VOCÊ CONSIDERA?", width: { wch: 18 } },
                            { title: "OUTROS PONTOS FORTES", width: { wch: 15 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA COMUNICATIVA EM QUAL NÍVEL?", width: { wch: 18 } },
                            { title: "VOCÊ PREFERE TRABALHOS EM EQUIPE OU INDIVIDUAL?", width: { wch: 10 } },
                            { title: "HÁ ALGUMA SITUAÇÃO EM QUE VOCÊ ENFRENTOU DESAFIOS AO TRABALHAR COM COLEGAS DE EQUIPE? COMO VOCÊ LIDOU COM ISSO?", width: { wch: 30 } },
                            { title: "VOCÊ SUGERIU OU IMPLEMENTOU ALGUMA MELHORIA SIGNIFICATIVA NOS PROCESSOS DE TRABALHO? SE SIM RELATE UM POUCO DESSAS IMPLEMENTAÇÕES:", width: { wch: 16 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA APTA A MUDANÇAS NO TRABALHO?", width: { wch: 18 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA PARA TRABALHAR SOB PRESSÃO?", width: { wch: 15 } },
                            { title: "DIANTE DO SEU DESENVOLVIMENTO PROFISSIONAL, TEM INTERESSE EM TRABALHAR EM ALGUM OUTRO DEPARTAMENTO OU FUNÇÃO? SE SIM, RELATE QUAL:", width: { wch: 15 } },
                            { title: "VOCÊ ESTÁ TOMANDO ALGUM PASSO PARA DESENVOLVER SUAS HABILIDADES E AVANÇAR EM SUA CARREIRA NO MOMENTO? SE SIM QUAIS?", width: { wch: 20 } },
                            { title: "QUAL SUGESTÃO VOCÊ TERIA PARA MELHORAR O CLIMA E A SATISFAÇÃO NA EMPRESA COMO UM TODO?", width: { wch: 20 } },
                            // { title: "OBSERVAÇÃO", width: { wch: 20 } },
                        ],
                        data: list
                    }])
                })
            }
            
        } else {
            mensagemAlerta('Acessos inválidos')
        }


    }
    useEffect(() => {
        const verificaAcesso = JSON.parse(localStorage.getItem('acesso'))
        const setor = localStorage.getItem('setor')
        setSetor(setor)
        if (verificaAcesso) {
            setAcesso(true)
            mensagemLoading('Carregando questionário')
            const list = [];
            var pontosFracos = '';
            var pontosFortes = '';
            if(setor == 'FULL'){
                servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').get().then((res) => {
                    const dados = res.docs.map((d) => d.data());
                    setLista(dados)
                    setAcesso(true)
                    dados.forEach((doc) => {
                        doc.dados.pontosFortes.forEach((pf)=>{
                            if(pf.check){
                                pontosFortes = pontosFortes == ''?pf.questao: pontosFortes+', \n'+pf.questao
                            }
                        })
                        doc.dados.pontosFracos.forEach((pf)=>{
                            if(pf.check){
                                pontosFracos = pontosFracos == ''?pf.questao: pontosFracos+', \n'+pf.questao
                            }
                        })
                        list.push([
                            { value: doc.dados.nome, style: { font: { sz: "11", } } },
                            { value: doc.dados.email, style: { font: { sz: "11", } } },
                            { value: doc.dados.unidade, style: { font: { sz: "11", } } },
                            { value: doc.dados.setor, style: { font: { sz: "11", } } },
                            { value: doc.dados.funcao, style: { font: { sz: "11", } } },
                            { value: doc.dados.faculdade, style: { font: { sz: "11", } } },
                            { value: doc.dados.descricaoFaculdade ? doc.dados.descricaoFaculdade : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: doc.dados.curso ? doc.dados.curso : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: pontosFracos, style: { font: { sz: "11", } } },
                            { value: doc.dados.observacaoPontoFraco ? doc.dados.observacaoPontoFraco : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: pontosFortes, style: { font: { sz: "11", } } },
                            { value: doc.dados.observacaoPontoForte ? doc.dados.observacaoPontoForte : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: doc.dados.nivelDesempenho, style: { font: { sz: "11", } } },
                            { value: doc.dados.tipoTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.desafiosTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.melhoria, style: { font: { sz: "11", } } },
                            { value: doc.dados.mudancaTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.pressao, style: { font: { sz: "11", } } },
                            { value: doc.dados.desenvolvimentoPessoal, style: { font: { sz: "11", } } },
                            { value: doc.dados.habilidades, style: { font: { sz: "11", } } },
                            { value: doc.dados.sugestaoMelhoria, style: { font: { sz: "11", } } },
                        ])
                    })
                    Swal.close();
                    setExcel([{
                        columns: [
                            { title: "NOME", width: { wch: 20 } },//pixels width 
                            { title: "EMAIL", width: { wch: 15 } },//char width 
                            { title: "UNIDADE", width: { wch: 30 } },//char width 
                            { title: "DEPARTAMENTO", width: { wch: 30 } },//char width 
                            { title: "QUAL É SUA FUNÇÃO ATUALMENTE EM REGISTRO?", width: { wch: 30 } },
                            { title: "POSSUI FACULDADE? CASO NÃO, POSSUI INTERESSE EM FAZER ALGUMA? DIGITE NA OPÇÃO EM OUTROS QUAL É DE SEU INTERESSE:", width: { wch: 20 } },
                            { title: "INTERESSE EM FACULDADE", width: { wch: 20 } },
                            { title: "SE POSSUÍ FACULDADE, QUAL CURSO VOCÊ FEZ OU ESTÁ CURSANDO?", width: { wch: 15 } },
                            { title: "QUAIS SÃO SEUS PRINCIPAIS PONTOS FRACOS QUE VOCÊ CONSIDERA E QUE PRETENDE MELHORAR?", width: { wch: 15 } },
                            { title: "OUTROS PONTOS FRACOS", width: { wch: 15 } },
                            { title: "QUAIS SÃO SEUS PRINCIPAIS PONTOS FORTES QUE VOCÊ CONSIDERA?", width: { wch: 18 } },
                            { title: "OUTROS PONTOS FORTES", width: { wch: 15 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA COMUNICATIVA EM QUAL NÍVEL?", width: { wch: 18 } },
                            { title: "VOCÊ PREFERE TRABALHOS EM EQUIPE OU INDIVIDUAL?", width: { wch: 10 } },
                            { title: "HÁ ALGUMA SITUAÇÃO EM QUE VOCÊ ENFRENTOU DESAFIOS AO TRABALHAR COM COLEGAS DE EQUIPE? COMO VOCÊ LIDOU COM ISSO?", width: { wch: 30 } },
                            { title: "VOCÊ SUGERIU OU IMPLEMENTOU ALGUMA MELHORIA SIGNIFICATIVA NOS PROCESSOS DE TRABALHO? SE SIM RELATE UM POUCO DESSAS IMPLEMENTAÇÕES:", width: { wch: 16 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA APTA A MUDANÇAS NO TRABALHO?", width: { wch: 18 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA PARA TRABALHAR SOB PRESSÃO?", width: { wch: 15 } },
                            { title: "DIANTE DO SEU DESENVOLVIMENTO PROFISSIONAL, TEM INTERESSE EM TRABALHAR EM ALGUM OUTRO DEPARTAMENTO OU FUNÇÃO? SE SIM, RELATE QUAL:", width: { wch: 15 } },
                            { title: "VOCÊ ESTÁ TOMANDO ALGUM PASSO PARA DESENVOLVER SUAS HABILIDADES E AVANÇAR EM SUA CARREIRA NO MOMENTO? SE SIM QUAIS?", width: { wch: 20 } },
                            { title: "QUAL SUGESTÃO VOCÊ TERIA PARA MELHORAR O CLIMA E A SATISFAÇÃO NA EMPRESA COMO UM TODO?", width: { wch: 20 } },
                            // { title: "OBSERVAÇÃO", width: { wch: 20 } },
                        ],
                        data: list
                    }])
                    Swal.close();
                })
            }else{
                servicos.questionario.doc('avaliacaodesempenho01').collection('questoes').where('dados.setor', '==', '' + setor).get().then((res) => {
                    const dados = res.docs.map((d) => d.data());
                    setLista(dados)
                    setAcesso(true)
                    dados.forEach((doc) => {
                        doc.dados.pontosFortes.forEach((pf)=>{
                            if(pf.check){
                                pontosFortes = pontosFortes == ''?pf.questao: pontosFortes+', \n'+pf.questao
                            }
                        })
                        doc.dados.pontosFracos.forEach((pf)=>{
                            if(pf.check){
                                pontosFracos = pontosFracos == ''?pf.questao: pontosFracos+', \n'+pf.questao
                            }
                        })
                        list.push([
                            { value: doc.dados.nome, style: { font: { sz: "11", } } },
                            { value: doc.dados.email, style: { font: { sz: "11", } } },
                            { value: doc.dados.unidade, style: { font: { sz: "11", } } },
                            { value: doc.dados.setor, style: { font: { sz: "11", } } },
                            { value: doc.dados.funcao, style: { font: { sz: "11", } } },
                            { value: doc.dados.faculdade, style: { font: { sz: "11", } } },
                            { value: doc.dados.descricaoFaculdade ? doc.dados.descricaoFaculdade : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: doc.dados.curso ? doc.dados.curso : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: pontosFracos, style: { font: { sz: "11", } } },
                            { value: doc.dados.observacaoPontoFraco ? doc.dados.observacaoPontoFraco : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: pontosFortes, style: { font: { sz: "11", } } },
                            { value: doc.dados.observacaoPontoForte ? doc.dados.observacaoPontoForte : 'Não respondido', style: { font: { sz: "11", } } },
                            { value: doc.dados.nivelDesempenho, style: { font: { sz: "11", } } },
                            { value: doc.dados.tipoTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.desafiosTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.melhoria, style: { font: { sz: "11", } } },
                            { value: doc.dados.mudancaTrabalho, style: { font: { sz: "11", } } },
                            { value: doc.dados.pressao, style: { font: { sz: "11", } } },
                            { value: doc.dados.desenvolvimentoPessoal, style: { font: { sz: "11", } } },
                            { value: doc.dados.habilidades, style: { font: { sz: "11", } } },
                            { value: doc.dados.sugestaoMelhoria, style: { font: { sz: "11", } } },
                        ])
                    })
                    Swal.close();
                    setExcel([{
                        columns: [
                            { title: "NOME", width: { wch: 20 } },//pixels width 
                            { title: "EMAIL", width: { wch: 15 } },//char width 
                            { title: "UNIDADE", width: { wch: 30 } },//char width 
                            { title: "DEPARTAMENTO", width: { wch: 30 } },//char width 
                            { title: "QUAL É SUA FUNÇÃO ATUALMENTE EM REGISTRO?", width: { wch: 30 } },
                            { title: "POSSUI FACULDADE? CASO NÃO, POSSUI INTERESSE EM FAZER ALGUMA? DIGITE NA OPÇÃO EM OUTROS QUAL É DE SEU INTERESSE:", width: { wch: 20 } },
                            { title: "INTERESSE EM FACULDADE", width: { wch: 20 } },
                            { title: "SE POSSUÍ FACULDADE, QUAL CURSO VOCÊ FEZ OU ESTÁ CURSANDO?", width: { wch: 15 } },
                            { title: "QUAIS SÃO SEUS PRINCIPAIS PONTOS FRACOS QUE VOCÊ CONSIDERA E QUE PRETENDE MELHORAR?", width: { wch: 15 } },
                            { title: "OUTROS PONTOS FRACOS", width: { wch: 15 } },
                            { title: "QUAIS SÃO SEUS PRINCIPAIS PONTOS FORTES QUE VOCÊ CONSIDERA?", width: { wch: 18 } },
                            { title: "OUTROS PONTOS FORTES", width: { wch: 15 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA COMUNICATIVA EM QUAL NÍVEL?", width: { wch: 18 } },
                            { title: "VOCÊ PREFERE TRABALHOS EM EQUIPE OU INDIVIDUAL?", width: { wch: 10 } },
                            { title: "HÁ ALGUMA SITUAÇÃO EM QUE VOCÊ ENFRENTOU DESAFIOS AO TRABALHAR COM COLEGAS DE EQUIPE? COMO VOCÊ LIDOU COM ISSO?", width: { wch: 30 } },
                            { title: "VOCÊ SUGERIU OU IMPLEMENTOU ALGUMA MELHORIA SIGNIFICATIVA NOS PROCESSOS DE TRABALHO? SE SIM RELATE UM POUCO DESSAS IMPLEMENTAÇÕES:", width: { wch: 16 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA APTA A MUDANÇAS NO TRABALHO?", width: { wch: 18 } },
                            { title: "VOCÊ SE CONSIDERA UMA PESSOA PARA TRABALHAR SOB PRESSÃO?", width: { wch: 15 } },
                            { title: "DIANTE DO SEU DESENVOLVIMENTO PROFISSIONAL, TEM INTERESSE EM TRABALHAR EM ALGUM OUTRO DEPARTAMENTO OU FUNÇÃO? SE SIM, RELATE QUAL:", width: { wch: 15 } },
                            { title: "VOCÊ ESTÁ TOMANDO ALGUM PASSO PARA DESENVOLVER SUAS HABILIDADES E AVANÇAR EM SUA CARREIRA NO MOMENTO? SE SIM QUAIS?", width: { wch: 20 } },
                            { title: "QUAL SUGESTÃO VOCÊ TERIA PARA MELHORAR O CLIMA E A SATISFAÇÃO NA EMPRESA COMO UM TODO?", width: { wch: 20 } },
                            // { title: "OBSERVAÇÃO", width: { wch: 20 } },
                        ],
                        data: list
                    }])
                    Swal.close();
                })
            }
        }

    }, [])

    function sair() {
        localStorage.setItem('acesso', false)
        localStorage.setItem('setor', null)
        window.location = '/acesso'
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    } function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="wrapper">
                            <div className="row no-gutters">
                                {acesso ?
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                        <div className="contact-wrap w-100 p-md-5 p-4 row">
                                            <div className="col-md-12 " style={{ textAlign: 'center' }}>
                                                <img src='images/logo.png' />
                                            </div>
                                            <h4 className="col-md-12 " style={{ marginTop: 30 }}>Veja a lista de questionários</h4>

                                            <div className="col-md-12 " style={{ marginTop: 20 }}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Nome</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Unidade</th>
                                                            <th scope="col">Detalhe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lista.map((d) =>
                                                            <tr>
                                                                <th>{d.dados.nome}</th>
                                                                <td>{d.dados.email}</td>
                                                                <td>{d.dados.unidade}</td>
                                                                <td>
                                                                    <Link className="btn " to={{ pathname: '/detalheQuestionario', state: { questao: d } }} >
                                                                        <i style={{ color: 'blue' }} class="fa fa-search" aria-hidden="true"></i>
                                                                    </Link>

                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {lista.length == 0 ?
                                                    <p>Não existem ainda questionários respondidos do seu setor</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div className="col-md-12 " >
                                                <div className="form-group">
                                                    <button style={{marginTop:18}} onClick={() => sair()} type="submit" className="btn btn-primary" >
                                                        Sair
                                                    </button>
                                                    <ExcelFile filename={setor == 'FULL'?'Lista de todos os colaboradores':'Lista dos colaboradores do setor '+setor} element={<button type="button" style={{marginLeft:10}} class="btn btn-success mb-1" >Download da lista em Excel</button>}>
                                                        <ExcelSheet dataSet={excel} name="Lista de Colaboradores" />
                                                    </ExcelFile>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    :
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                        <div className="contact-wrap w-100 p-md-5 p-4 row">
                                            <div className="col-md-12">
                                                <img src='images/logo.png' />
                                            </div>
                                            <h4 className="col-md-12" style={{ marginTop: 30 }}>Acesso a lista</h4>
                                            <div className="col-md-6" style={{ marginTop: 30 }}>
                                                <div className="form-group">
                                                    <label className="label" htmlFor="subject">Email </label>
                                                    <input value={email} onChange={handleEmail} type="text" className="form-control" name="subject" id="subject" placeholder="email@gmail.com" />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 30 }}>
                                                <div className="form-group">
                                                    <label className="label" htmlFor="subject">Senha </label>
                                                    <input value={senha} onChange={handleSenha} type="text" className="form-control" name="subject" id="subject" placeholder="******" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 " >
                                                <div className="form-group">
                                                    <button onClick={() => login()} type="submit" className="btn btn-primary" >
                                                        Acessar
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                                <div className="col-lg-4 col-md-5 d-flex align-items-stretch" >
                                    <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                                        <h3>Avaliação de Desempenho<br />Um Caminho para o Crescimento </h3>
                                        <p className="mb-4"></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}