import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Autenticacao
import Acesso from './pages/Acesso';
import Home from './pages/Home';
import Questionario from './pages/Questionario';
import DetalheQuestionario from './pages/DetalheQuestionario';

export default class Routes extends Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    
                    {/* Autenticacao */}
                    <Route path="/" exact={true} component={Home} />
                    <Route path="/acesso" exact={true} component={Acesso} />
                    <Route path="/questao" exact={true} component={Questionario} />
                    <Route path="/detalheQuestionario" exact={true} component={DetalheQuestionario} />

                </BrowserRouter>
            </div>
        );
    }
}